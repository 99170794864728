@import "Styles/stats-popup.css";
@import "Styles/spinner.css";
@import "Styles/buttons.css";



.App {
    text-align: center;
    background-color: #FFFFFF;
    color: black;
}

.page-content {
    padding-top: 55px;
    min-height: calc(100vh - 30px);
}

.list-items-stats{
    list-style: none;
    padding-left: 0 !important;
}

.footer {
    height: 50px;
    padding-top:20px;
}

.ui.top.fixed.menu{
    background-color:#e30613;
}

.ui.menu:not(.vertical) .item{
    color: white;
}
