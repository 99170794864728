.hit-box-container{
    position: relative;
    display: inline-block;
    flex-direction: column;
    width:auto;
    height:auto;
    margin: 0 5px;
}

.hitbox{
    width:100%;
    height:100%;
    display: block;
}

.info-box{
    display: flex;
    position: absolute !important;
    left:50%;
    opacity: 0;
    top:0;
    transform: translate(-50%, -40px);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-width: 200px;
    height:100%;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.hitbox.stats:hover + .info-box.stats{
    opacity: 1;
    z-index: 100;
}

.hitbox.redo:hover + .info-box.redo{
    opacity: 1;
    z-index: 100;
}

.hitbox.skip:hover + .info-box.skip{
    opacity: 1;
    z-index: 100;
}

.hitbox.download:hover + .info-box.download{
    opacity: 1;
    z-index: 100;
}


.hitbox.uploadError:hover + .info-box.uploadError{
    opacity: 1;
    z-index: 100;
}

.hitbox.configurationError:hover + .info-box.configurationError{
    opacity: 1;
    z-index: 100;
}
